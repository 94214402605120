import React, { useEffect, useState } from "react";
import Worldbookview from "./Worldbookview";
import "./HandPrint.css";
import Stepbar from "./Stepbar";
import Modelwrapper from "./popups/Modelwrapper";
import Firstname from "./popups/Firstname";
import Customisebook from "./popups/Customisebook";
import Booktitle from "./popups/customisebook/Booktitle";
import Yearset from "./popups/customisebook/Yearset";
import Favouriteplace from "./popups/customisebook/Favouriteplace";
import Menuitems from "./popups/customisebook/Menuitems";
import Inepirationalmessage from "./popups/customisebook/Inepirationalmessage";
import Imageupload from "./popups/Imageupload";
import Uploadone from "./popups/Imageupload/Uploadone";
import Uploadtwo from "./popups/Imageupload/Uploadtwo";
import Uploadthree from "./popups/Imageupload/Uploadthree";
import Uploadfour from "./popups/Imageupload/Uploadfour";
import Uploadfive from "./popups/Imageupload/Uploadfive";
import { useHistory, Link } from "react-router-dom";
import Dedication from "./popups/customisebook/Dedication";
// Pages
import Coverone from "./txtpages/coverone.txt";
import Covertwo from "./txtpages/covertwo.txt";
import Pageone from "./txtpages/Page1.txt";
import Pagetwo from "./txtpages/Page2.txt";
import Pagethree from "./txtpages/Page3.txt";
import Pagefour from "./txtpages/Page4.txt";
import Pagefive from "./txtpages/Page5.txt";
import Pagesix from "./txtpages/Page6.txt";
import Pageseven from "./txtpages/Page7.txt";
import Pageeight from "./txtpages/Page8.txt";
import Pagenine from "./txtpages/Page9.txt";
import Pageten from "./txtpages/Page10.txt";
import Pageeleven from "./txtpages/Page11.txt";
import Pagetwelve from "./txtpages/Page12.txt";
import Pagethirteen from "./txtpages/Page13.txt";
import Pageforteen from "./txtpages/Page14.txt";
import Pagefifteen from "./txtpages/Page15.txt";
import Pagesixteen from "./txtpages/Page16.txt";
import Pageseventeen from "./txtpages/Page17.txt";
import Pageeighteen from "./txtpages/Page18.txt";
import Pageninteen from "./txtpages/Page19.txt";
import Pagetwenty from "./txtpages/Page20.txt";
import Pagetwentyone from "./txtpages/Page21.txt";
import Pagetwentytwo from "./txtpages/Page22.txt";
import Pagetwentythree from "./txtpages/Page23.txt";
import Pagetwentyfour from "./txtpages/Page24.txt";
import Pagetwentyfive from "./txtpages/Page25.txt";
import Pagetwentysix from "./txtpages/Page26.txt";
import Pagetwentyseven from "./txtpages/Page27.txt";
import Pagetwentyeight from "./txtpages/Page28.txt";
import { useParams } from "react-router-dom";

const Worldbook = (props) => {
  const history = useHistory();
  const { id, customBookId } = useParams();
  const [preinfoCompleted, setPreinfoCompleted] = useState(false);
  const worldbookPreInfo = props.store?.book?.worldbookPreInfo;
  const answer = worldbookPreInfo?.answer;
  const [isFading, setIsFading] = useState(false);
  const [formdata, setFormdata] = useState(answer || {});
  const [pages, setPages] = useState([]);
  const handlePreinfoSave = () => {
    let tempformdata = {
      ...formdata,
      message_one: formdata?.message_one
        ? formdata?.message_one
        : "A sense of adventure",
      message_two: formdata?.message_two
        ? formdata?.message_two
        : "Hugs that warm you wherever you are ",
      message_three: formdata?.message_three
        ? formdata?.message_three
        : "Reminder: you are always loved and never alone",
    };
    let newanswer = { ...Object.deepClone(tempformdata) };
    let payload = Object.deepClone(newanswer);
    payload.answer = newanswer;
    setFormdata(payload.answer);
    mixins.setStore("book.worldbookPreInfo", payload, props);
    if (
      preinfoCompleted == "booktitle" ||
      preinfoCompleted == "year" ||
      preinfoCompleted == "place" ||
      preinfoCompleted == "food" ||
      preinfoCompleted == "message" ||
      preinfoCompleted == "dedication"
    ) {
      handleClick("bookinfo");
    } else if (
      preinfoCompleted == "imageone" ||
      preinfoCompleted == "imagetwo" ||
      preinfoCompleted == "imagethree" ||
      preinfoCompleted == "imagefour" ||
      preinfoCompleted == "imagefive"
    ) {
      handleClick("imageupload");
    } else {
      handleClick(false);
    }
  };
  const fetchPages = async () => {
    try {
      const pageUrls = [
        // { coverone: Coverone },
        // { covertwo: Covertwo },
        { pageone: Pageone },
        { pagetwo: Pagetwo },
        { pagethree: Pagethree },
        { pagefour: Pagefour },
        { pagefive: Pagefive },
        { pagesix: Pagesix },
        { pageseven: Pageseven },
        { pageeight: Pageeight },
        { pagenine: Pagenine },
        { pageten: Pageten },
        { pageeleven: Pageeleven },
        { pagetwelve: Pagetwelve },
        { pagethirteen: Pagethirteen },
        { pageforteen: Pageforteen },
        { pagefifteen: Pagefifteen },
        { pagesixteen: Pagesixteen },
        { pageseventeen: Pageseventeen },
        { pageeighteen: Pageeighteen },
        { pageninteen: Pageninteen },
        { pagetwenty: Pagetwenty },
        { pagetwentyone: Pagetwentyone },
        { pagetwentytwo: Pagetwentytwo },
        { pagetwentythree: Pagetwentythree },
        { pagetwentyfour: Pagetwentyfour },
        { pagetwentyfive: Pagetwentyfive },
        { pagetwentysix: Pagetwentysix },
        { pagetwentyseven: Pagetwentyseven },
        { pagetwentyeight: Pagetwentyeight },
      ];

      const pagePromises = pageUrls.map(async (pageUrlObj) => {
        const pageKey = Object.keys(pageUrlObj)[0]; // Get the key name (e.g., 'pageone')
        const response = await fetch(pageUrlObj[pageKey]); // Fetch the page using the URL
        const content = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        if (answer?.name) {
          const elements = doc.querySelectorAll('[id="child_name"]');
          elements.forEach((element) => {
            element.textContent = answer?.name;
          });
        }
        if (answer?.menu_one) {
          const elements = doc.querySelectorAll('[id="custom_food_one"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_one;
          });
        }
        if (answer?.menu_two) {
          const elements = doc.querySelectorAll('[id="custom_food_two"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_two;
          });
        }
        if (answer?.menu_three) {
          const elements = doc.querySelectorAll('[id="custom_food_three"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_three;
          });
        }
        if (answer?.menu_four) {
          const elements = doc.querySelectorAll('[id="custom_food_four"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_four;
          });
        }
        if (answer?.menu_five) {
          const elements = doc.querySelectorAll('[id="custom_food_five"]');
          elements.forEach((element) => {
            element.textContent = answer?.menu_five;
          });
        }
        if (answer?.fav_place) {
          const elements = doc.querySelectorAll('[id="favourite_place"]');
          elements.forEach((element) => {
            element.textContent = answer?.fav_place;
          });
        }
        if (answer?.message_one) {
          const elements = doc.querySelectorAll('[id="message_one"]');
          elements.forEach((element) => {
            element.textContent = answer?.message_one;
          });
        }
        if (answer?.message_two) {
          const elements = doc.querySelectorAll('[id="message_two"]');
          elements.forEach((element) => {
            element.textContent = answer?.message_two;
          });
        }
        if (answer?.message_three) {
          const elements = doc.querySelectorAll('[id="message_three"]');
          elements.forEach((element) => {
            element.textContent = answer?.message_three;
          });
        }
        if (answer?.year) {
          const elements = doc.querySelectorAll('[id="custom_year"]');
          elements.forEach((element) => {
            element.textContent = answer?.year;
          });
        }
        if (answer?.book_title) {
          const elements = doc.querySelectorAll('[id="book_title"]');
          elements.forEach((element) => {
            element.textContent = answer?.book_title;
          });
        }
        if (answer?.dedication) {
          const elements = doc.querySelectorAll('[id="dedication"]');
          elements.forEach((element) => {
            element.textContent = answer?.dedication;
          });
        }
        if (answer?.image_assets?.image_one) {
          const elements = doc.querySelectorAll('[id="image_one"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_one;
            element.style.display = "block";
          });
        }
        if (answer?.image_assets?.image_two) {
          const elements = doc.querySelectorAll('[id="image_two"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_two;
            element.style.display = "block";
          });
        }
        if (answer?.image_assets?.image_three) {
          const elements = doc.querySelectorAll('[id="image_three"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_three;
            element.style.display = "block";
          });
        }
        if (answer?.image_assets?.image_four) {
          const elements = doc.querySelectorAll('[id="image_four"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_four;
            element.style.display = "block";
          });
        }
        if (answer?.image_assets?.image_five) {
          const elements = doc.querySelectorAll('[id="image_five"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_five;
            element.style.display = "block";
          });
        }
        if (answer?.image_assets?.image_six) {
          const elements = doc.querySelectorAll('[id="image_six"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_six;
            element.style.display = "block";
          });
        }
        if (answer?.image_assets?.image_seven) {
          const elements = doc.querySelectorAll('[id="image_seven"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_seven;
            element.style.display = "block";
          });
        }
        if (answer?.image_assets?.image_eight) {
          const elements = doc.querySelectorAll('[id="image_eight"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_eight;
            element.style.display = "block";
          });
        }
        if (answer?.image_assets?.image_nine) {
          const elements = doc.querySelectorAll('[id="image_nine"]');
          elements.forEach((element) => {
            element.src = answer.image_assets.image_nine;
            element.style.display = "block";
          });
        }
        return { [pageKey]: new XMLSerializer().serializeToString(doc) };
      });
      const pagesContent = await Promise.all(pagePromises);
      setPages(pagesContent);
    } catch (error) {
      console.error("Error fetching pages:", error);
    }
  };
  const handleClick = (componentname) => {
    setIsFading(true);
    if (preinfoCompleted != componentname) {
      setTimeout(() => {
        setPreinfoCompleted(componentname);
        setIsFading(false);
      }, 500);
    } else {
      setPreinfoCompleted(false);
    }
  };
  const handleclose = () => {
    if (answer?.name) {
      if (
        preinfoCompleted == "booktitle" ||
        preinfoCompleted == "year" ||
        preinfoCompleted == "place" ||
        preinfoCompleted == "food" ||
        preinfoCompleted == "message" ||
        preinfoCompleted == "dedication"
      ) {
        handleClick("bookinfo");
      } else if (
        preinfoCompleted == "imageone" ||
        preinfoCompleted == "imagetwo" ||
        preinfoCompleted == "imagethree" ||
        preinfoCompleted == "imagefour" ||
        preinfoCompleted == "imagefive"
      ) {
        handleClick("imageupload");
      } else {
        handleClick(false);
      }
    } else {
      history.push(`/custom-books/product-details/${id}`);
    }
  };
  const handlebackbtn = () => {
    if (answer?.name) {
      handleClick(false);
    } else {
      history.push(`/custom-books/product-details/${id}`);
    }
  };
  useEffect(() => {
    if (answer?.name) {
      handleClick(false);
    } else {
      handleClick("name");
    }
  }, []);
  useEffect(() => {
    if (customBookId) {
      getLatestBook();
    }
  }, []);
  useEffect(() => {
    fetchPages();
  }, [worldbookPreInfo]);

  const processPages = (pages) => {
    const parser = new DOMParser();
    return pages.reduce((acc, item) => {
      const pageKey = Object.keys(item)[0];
      let doc = parser.parseFromString(item[pageKey], "text/html");
      let bookcontent = doc.body.innerHTML;
      acc[pageKey] = { html: bookcontent };
      return acc;
    }, {});
  };
  const getLatestBook = () => {
    getLatestBookCall(customBookId)
      .then((res) => {})
      .catch((rej) => {
        console.log(rej);
      });
  };
  const addtocart = () => {
    const parser = new DOMParser();
    let allpages = processPages(pages);
    console.log("Data ", formdata);
    let textdata = {
      message_one: formdata?.message_one,
      message_two: formdata?.message_two,
      message_three: formdata?.message_three,
      name: formdata?.name,
      menu_one: formdata?.menu_one,
      menu_two: formdata?.menu_two,
      menu_three: formdata?.menu_three,
      menu_four: formdata?.menu_four,
      menu_five: formdata?.menu_five,
      fav_place: formdata?.fav_place,
      year: formdata?.year,
      dedication: formdata?.dedication,
    };
    let images = [
      {
        chapter: "14",
        image: formdata?.image?.image_one,
      },
      {
        chapter: "19_1",
        image: formdata?.image?.image_two,
      },
      {
        chapter: "19_2",
        image: formdata?.image?.image_three,
      },
      {
        chapter: "20_1",
        image: formdata?.image?.image_four,
      },
      {
        chapter: "20_2",
        image: formdata?.image?.image_five,
      },
      {
        chapter: "21_1",
        image: formdata?.image?.image_six,
      },
      {
        chapter: "21_2",
        image: formdata?.image?.image_seven,
      },
      {
        chapter: "22_1",
        image: formdata?.image?.image_eight,
      },
      {
        chapter: "22_2",
        image: formdata?.image?.image_nine,
      },
    ];
    let payload = {
      file: null,
      bookId: id,
      savedBook: JSON.stringify(allpages),
      option: "dynamic",
      sessionId: localStorage.getItem("sessionID") || null,
      type: "worldbook",
      attributes: JSON.stringify(textdata),
      customcharacterpng: JSON.stringify(images),
      firstname: "Govind ",
      lastname: "Sharma" || null,
      dedication: "Govind",
      gender: "male",
    };

    mixins.checkLogin(history).then(() => {
      if (customBookId) {
        api.updateSelfPublish(payload, customBookId).then((responce) => {
          getLatestBookCall(responce.data.id)
            .then((res) => {
              addtoCart(res);
            })
            .catch((rej) => {
              console.log(rej);
            });
        });
      } else {
        api.createSelfPublish(payload).then((responce) => {
          mixins.reFectchSavedBookDetails(props);
          history.push(history.location.pathname + `/${responce.data.id}`);
          setTimeout(() => {
            getLatestBookCall(responce.data.id)
              .then((res) => {
                addtoCart(res);
              })
              .catch((rej) => {
                console.log(rej);
              });
          }, 500);
        });
      }
    });
  };
  const getLatestBookCall = (id) => {
    return new Promise((resolve, reject) => {
      api
        .getSelfPublish(id)
        .then((response) => {
          if (response && response.data) {
            let responcedata = response.data;
            let arrtibutes = JSON.parse(responcedata?.attributes);
            let newanswer = { ...Object.deepClone(arrtibutes) };
            let payload = Object.deepClone(newanswer);
            payload.answer = newanswer;
            setFormdata(payload.answer);
            mixins.setStore("book.worldbookPreInfo", payload, props);
            setTimeout(() => {
              resolve(responcedata);
            }, 0);
          } else {
            resolve(this.state.savedBook);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const addtoCart = (responce) => {
    api
      .addToCart({
        promotionId: null,
        productId: props.store.product.id,
        quantity: 1,
        customBookId: responce?.customBook?.id,
        price: props.store.product.actualPrice,
        discount: null,
        bookCover: null,
      })
      .then((res) => {
        if (res && res.statusCode === 200) {
          history.push("/checkout-page");
        } else {
          console.log("res", res);
        }
      });
  };
  return (
    <div className="worldbook">
      <Worldbookview answer={answer} pages={pages} />
      {!preinfoCompleted && (
        <>
          <Stepbar
            name={answer?.name}
            handleopen={handleClick}
            preinfoCompleted={preinfoCompleted}
            addtocart={addtocart}
            handlePreinfoSave={handlePreinfoSave}
          />
        </>
      )}

      {preinfoCompleted && (
        <Modelwrapper open={preinfoCompleted} handleClose={handleclose}>
          <div
            className={`form-container ${isFading ? "fade-out" : "fade-in"}`}
          >
            {preinfoCompleted == "name" && (
              <Firstname
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "bookinfo" && (
              <Customisebook
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
                handleClick={handleClick}
              />
            )}
            {preinfoCompleted == "booktitle" && (
              <Booktitle
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "year" && (
              <Yearset
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "place" && (
              <Favouriteplace
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "food" && (
              <Menuitems
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "message" && (
              <Inepirationalmessage
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "dedication" && (
              <Dedication
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "imageupload" && (
              <Imageupload
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
                handleClick={handleClick}
              />
            )}
            {preinfoCompleted == "imageone" && (
              <Uploadone
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "imagetwo" && (
              <Uploadtwo
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "imagethree" && (
              <Uploadthree
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "imagefour" && (
              <Uploadfour
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
            {preinfoCompleted == "imagefive" && (
              <Uploadfive
                formdata={formdata}
                setFormdata={setFormdata}
                handlePreinfoSave={handlePreinfoSave}
                handlebackbtn={handlebackbtn}
              />
            )}
          </div>
          {answer?.name && (
            <Stepbar
              name={answer?.name}
              handleopen={handleClick}
              preinfoCompleted={preinfoCompleted}
              handlePreinfoSave={handlePreinfoSave}
              addtocart={addtocart}
            />
          )}
        </Modelwrapper>
      )}
    </div>
  );
};

export default connect(Worldbook);
