import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/left-dedication.png";

const Dedication = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    handlePreinfoSave();
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>
                  More space for a special message or leave it blank for
                  autographs
                </h4>
                <textarea
                  ref={nameRef}
                  placeholder="A special message"
                  value={formdata?.dedication || ""}
                  onChange={(e) => {
                    handleChange({ ...formdata, dedication: e.target.value });
                  }}
                  maxLength={720}
                  rows={4} // Adjust the number of rows as needed
                  cols={50} // Adjust the number of columns as needed
                />
                {alert && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {alert}
                  </span>
                )}

                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                  Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Dedication);
