import React, { useEffect, useRef, useState } from "react";
import "./HandprintView.css";
import HTMLFlipBook from "react-pageflip";
import thumbimgone from "./../../assets/images/worldbook/thumb-1.jpg";
import thumbimgtwo from "./../../assets/images/worldbook/thumb-2.jpg";
import thumbimgthree from "./../../assets/images/worldbook/thumb-3.jpg";
import thumbimgfour from "./../../assets/images/worldbook/thumb-4.jpg";
import thumbimgfive from "./../../assets/images/worldbook/thumb-5.jpg";

const Worldbookview = (props) => {
  const bookRef = useRef(null);
  const { pages, answer } = props;
  const handleopenBook = (data) => {
    if (bookRef.current) {
      bookRef.current.pageFlip().turnToPage(data);
    }
  };

  return (
    <div className="HandPrint Worldbookview top-margin">
      <div className="container">
        <div className="row no-gutters space">
          <div className="col-12">
            <h2 className="fs-title">Preview your personalised book</h2>
            <div className="book-thumbnail">
              <ul>
                <li
                  onClick={() => {
                    handleopenBook(2);
                  }}
                >
                  <img src={thumbimgone} alt="" />
                </li>
                <li
                  onClick={() => {
                    handleopenBook(9);
                  }}
                >
                  <img src={thumbimgthree} alt="" />
                </li>
                <li
                  onClick={() => {
                    handleopenBook(18);
                  }}
                >
                  <img src={thumbimgfour} alt="" />
                </li>
                <li
                  onClick={() => {
                    handleopenBook(22);
                  }}
                >
                  <img src={thumbimgtwo} alt="" />
                </li>

                <li
                  onClick={() => {
                    handleopenBook(23);
                  }}
                >
                  <img src={thumbimgfive} alt="" />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="handprint">
              {pages.length > 0 && (
                <HTMLFlipBook
                  width={200}
                  height={200}
                  size="stretch"
                  maxShadowOpacity={0.5}
                  showCover={true}
                  mobileScrollSupport={true}
                  className="demo-book"
                  ref={bookRef}
                >
                  {pages.map((pageContent, index) => {
                  const pageKey = Object.keys(pageContent)[0]; 
                    return (
                      <div
                        key={index}
                        className={`demoPage ${
                          index === 0
                            ? "front-page"
                            : index === pages.length - 1
                            ? "backcover"
                            : index % 2 === 1
                            ? "left-page"
                            : "right-page"
                        }`}
                        style={{ position: "relative" }}
                        dangerouslySetInnerHTML={{
                          __html: pageContent[pageKey],
                        }}
                      ></div>
                    );
                  })}
                </HTMLFlipBook>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Worldbookview);
