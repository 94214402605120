import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/favourite-place.jpg";

const Favouriteplace = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    if (!formdata?.fav_place) {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>Where is the place that is The Spot for your friend(s) or family? </h4>
                <p>Think of a familiar place. Somewhere instantly recognisable.</p>
                <input
                  ref={nameRef}
                  type="text"
                  maxLength={26}
                  placeholder="Please enter favourite place"
                  value={formdata?.fav_place || ""}
                  onChange={(e) => {
                    handleChange({ ...formdata, fav_place: e.target.value });
                  }}
                />
                {alert && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {alert}
                  </span>
                )}

                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                  Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Favouriteplace);
