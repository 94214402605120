import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/cover-year.jpg";

const Yearset = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    if (!formdata?.year) {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>What milestone is being celebrated?</h4>
                <p>Some fun examples: <i>Graduating year of 2024, Internship completed 2025, New job starts 2026, New School, Moving Abroad, Australia 2028</i></p>
                <input
                  ref={nameRef}
                  type="text"
                  maxLength={40}
                  placeholder="Please enter title of Calendar"
                  value={formdata?.year || ""}
                  onChange={(e) => {
                    handleChange({ ...formdata, year: e.target.value });
                  }}
                />
                {alert && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {alert}
                  </span>
                )}

                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                  Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Yearset);
