import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/photo-rectangle.jpg";

const Uploadone = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [images, setImages] = useState(formdata?.image || {});
  const [imagesassets, setImagesassets] = useState(
    formdata?.image_assets || {}
  );
  const [alert, setAlert] = useState(null);
  const nameRef = useRef();

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!images?.image_one) {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    setFormdata({
      ...formdata,
      image: images,
      image_assets: imagesassets,
    });
  }, [images, imagesassets]);
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>Photo 1</h4>
                <p>Upload a silly photo</p>
                <input
                  ref={nameRef}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      const base64String = reader.result;
                      setImages({
                        ...images,
                        image_one: file,
                      });
                      setImagesassets({
                        ...imagesassets,
                        image_one: base64String,
                      });
                    };

                    reader.readAsDataURL(file);
                  }}
                />
                {alert && (
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {alert}
                  </span>
                )}

                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                  Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Uploadone);
