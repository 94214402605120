import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/menu-item.jpg";

const Menuitems = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    if (
      !formdata?.menu_one ||
      !formdata?.menu_two ||
      !formdata?.menu_three ||
      !formdata?.menu_four ||
      !formdata?.menu_five
    ) {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>Create a favourite or dream menu </h4>
                <p>List all the goodies that bring warm memories. </p>
                <div class="form-info">
                  <p>Favourite food</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite food"
                    maxLength={25}
                    value={formdata?.menu_one || ""}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        menu_one: e.target.value,
                      });
                    }}
                  />
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Favourite food</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite food"
                    value={formdata?.menu_two || ""}
                    maxLength={25}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        menu_two: e.target.value,
                      });
                    }}
                  />
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Favourite snack</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite snack"
                    value={formdata?.menu_three || ""}
                    maxLength={25}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        menu_three: e.target.value,
                      });
                    }}
                  />
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Favourite food</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite food"
                    value={formdata?.menu_four || ""}
                    maxLength={25}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        menu_four: e.target.value,
                      });
                    }}
                  />
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Favourite food</p>
                  <input
                    type="text"
                    placeholder="Please enter favourite food"
                    value={formdata?.menu_five || ""}
                    maxLength={25}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        menu_five: e.target.value,
                      });
                    }}
                  />
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                  Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Menuitems);
