import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/inspirational-msg.jpg";

const Inepirationalmessage = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [alert, setAlert] = useState(null);
  const nameRef = useRef();
  const handleChange = (val) => {
    setFormdata(val);
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    if (
      !formdata?.message_one ||
      !formdata?.message_two ||
      !formdata?.message_three
    ) {
      setAlert("Field is required.");
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <h4>Words of hope that would make any journey brighter.</h4>
                <p>Encouraging words that bring on a smile when times get dark. We’ve filled them for you, but feel free to change them if you wish!</p>
                <div class="form-info">
                  <p>Positive messages 1</p>
                  <textarea
                    className="mini-msg"
                    name="book-textarea"
                    cols=""
                    rows=""
                    maxLength={27}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        message_one: e.target.value,
                      });
                    }}
                  >
                    {formdata?.message_one || ""}
                  </textarea>
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Positive messages 2</p>
                  <textarea
                    className="mini-msg"
                    name="book-textarea"
                    cols=""
                    rows=""
                    maxLength={40}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        message_two: e.target.value,
                      });
                    }}
                  >
                    {formdata?.message_two || ""}
                  </textarea>
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div class="form-info">
                  <p>Positive messages 3</p>
                  <textarea
                    className="mini-msg"
                    name="book-textarea"
                    cols=""
                    rows=""
                    maxLength={80}
                    onChange={(e) => {
                      handleChange({
                        ...formdata,
                        message_three: e.target.value,
                      });
                    }}
                  >
                    {formdata?.message_three || ""}
                  </textarea>
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>

                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                  Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Inepirationalmessage);
