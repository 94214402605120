import React from "react";
import checkimage from "../../../assets/images/worldbook/check.svg";

const Customisebook = (props) => {
  const {
    formdata,
    handleClick = () => {},
    handlePreinfoSave = () => {},
  } = props;
  return (
    <div className="tabs">
      <div id="msform">
        <fieldset className="box-preview">
          <div className="form-card">
            <div className="row">
              <div className="col-12 canvas-info">
                <p>
                  Have fun and customise these parts of the story! Fill it with
                  humorous or heartwarming bits and fond memories.
                </p>
                <p>
                  You can save and preview your changes, and click back into any
                  item to change it again.
                </p>
                <p>
                  We’ll print exactly what you’ve typed, so do double-check!
                  Please only use letters, numbers and keyboard characters (no
                  emojis).
                </p>
              </div>
            </div>
            <div className="row">
              <div className="levelForm">
                <div className="LevelForm-list">
                  <div
                    className="form-list-item"
                    onClick={() => {
                      handleClick("booktitle");
                    }}
                  >
                    <div className="boxInner">
                      <h4>1. Bunny’s Book Title</h4>
                      <div className="item-content">
                        <img src={checkimage} alt="" />
                        <p>Title of book held by bunny</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="form-list-item"
                    onClick={() => {
                      handleClick("food");
                    }}
                  >
                    <div className="boxInner">
                      <h4>2. Food Memories</h4>
                      <div className="food-item-container">
                        <div className="item-content">
                          <img src={checkimage} alt="" />
                          <p>{"Favourite food"}</p>
                        </div>
                        <div className="item-content">
                          <img src={checkimage} alt="" />
                          <p>{"Favourite food"}</p>
                        </div>
                        <div className="item-content">
                          <img src={checkimage} alt="" />
                          <p>{"Favourite snack"}</p>
                        </div>
                        <div className="item-content">
                          <img src={checkimage} alt="" />
                          <p> {"Favourite food"}</p>
                        </div>
                        <div className="item-content">
                          <img src={checkimage} alt="" />
                          <p> {"Favourite food"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="form-list-item"
                    onClick={() => {
                      handleClick("place");
                    }}
                  >
                    <div className="boxInner">
                      <h4>3. Favourite Place</h4>
                      <div className="item-content">
                        <img src={checkimage} alt="" />
                        <p>{"Frequent hangout"}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="form-list-item"
                    onClick={() => {
                      handleClick("message");
                    }}
                  >
                    <div className="boxInner">
                      <h4>4. Inspirational Messages</h4>
                      <div className="item-content">
                        <img src={checkimage} alt="" />
                        <p>{"Positive message 1"}</p>
                      </div>
                      <div className="item-content">
                        <img src={checkimage} alt="" />
                        <p>{"Positive message 2"}</p>
                      </div>
                      <div className="item-content">
                        <img src={checkimage} alt="" />
                        <p>{"Positive message 3"}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="form-list-item"
                    onClick={() => {
                      handleClick("year");
                    }}
                  >
                    <div className="boxInner">
                      <h4>5. Title of Calendar</h4>
                      <div className="item-content">
                        <img src={checkimage} alt="" />
                        <p>{"Year or event"}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="form-list-item"
                    onClick={() => {
                      handleClick("dedication");
                    }}
                  >
                    <div className="boxInner">
                      <h4>6. Dedication (optional)</h4>
                      <div className="item-content">
                        <img src={checkimage} alt="" />
                        <p>{"A special message"}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <button onClick={handlePreinfoSave} className="action-button">
            Save & Preview
          </button> */}
        </fieldset>
      </div>
    </div>
  );
};

export default connect(Customisebook);
