import React, { useEffect, useRef, useState } from "react";
import titleimg from "../../../../assets/images/worldbook/photo-large.jpg";

const Uploadfive = (props) => {
  const {
    formdata,
    setFormdata,
    handlePreinfoSave = () => {},
    handlebackbtn = () => {},
  } = props;
  const [images, setImages] = useState(formdata?.image || {});
  const [imagesassets, setImagesassets] = useState(
    formdata?.image_assets || {}
  );
  const [alert, setAlert] = useState(null);
  const nameRef = useRef();

  const handlesubmit = (e) => {
    e.preventDefault();
    if (!images?.image_eight || !images?.image_nine) {
      setAlert("Field is required.");
      nameRef.current.focus();
    } else {
      handlePreinfoSave();
    }
  };
  useEffect(() => {
    setFormdata({
      ...formdata,
      image: images,
      image_assets: imagesassets,
    });
  }, [images, imagesassets]);
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
        setAlert(null);
      }, 2000);
    }
  }, [alert]);

  return (
    <div id="msform">
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="picture">
                <img src={titleimg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="rt-content">
                <div>
                  <h4>Photo 8</h4>
                  <p>Upload a group photo.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const base64String = reader.result;
                        setImages({
                          ...images,
                          image_eight: file,
                        });
                        setImagesassets({
                          ...imagesassets,
                          image_eight: base64String,
                        });
                      };

                      reader.readAsDataURL(file);
                    }}
                  />
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div>
                  <h4>Photo 9 </h4>
                  <p>Upload a photo of a happy moment.</p>
                  <input
                    ref={nameRef}
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const base64String = reader.result;
                        setImages({
                          ...images,
                          image_nine: file,
                        });
                        setImagesassets({
                          ...imagesassets,
                          image_nine: base64String,
                        });
                      };

                      reader.readAsDataURL(file);
                    }}
                  />
                  {alert && (
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      {alert}
                    </span>
                  )}
                </div>
                <div className="save-btn">
                  <button className="action-button" onClick={handlesubmit}>
                  Save & Back to List
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(Uploadfive);
